var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TablePage", {
        ref: "tablePage",
        staticClass: "tablepage",
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 500, showDialog: _vm.open },
        on: {
          "update:showDialog": function ($event) {
            _vm.open = $event
          },
          "update:show-dialog": function ($event) {
            _vm.open = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingDia,
                        expression: "loadingDia",
                      },
                    ],
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "80px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "上级类别", prop: "parentId" },
                              },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options:
                                      _vm.categoryTreeOptionsUnclassified,
                                    "show-count": true,
                                    placeholder: "请选择上级类别",
                                    normalizer: _vm.normalizer,
                                    open: _vm.getTreeselect,
                                  },
                                  on: { input: _vm.changeParentId },
                                  model: {
                                    value: _vm.form.parentId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "parentId", $$v)
                                    },
                                    expression: "form.parentId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "类别编码",
                                  prop: "categoryNo",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "x-fc" },
                                  [
                                    _c("el-input", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.form.parentId != 0,
                                          expression: "form.parentId != 0",
                                        },
                                      ],
                                      attrs: {
                                        placeholder: "请选择上级类别",
                                        disabled: "",
                                      },
                                      model: {
                                        value: _vm.form.categoryNo1,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "categoryNo1", $$v)
                                        },
                                        expression: "form.categoryNo1",
                                      },
                                    }),
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入类别编码",
                                        maxlength: "13",
                                      },
                                      model: {
                                        value: _vm.form.categoryNo,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "categoryNo", $$v)
                                        },
                                        expression: "form.categoryNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "类别名称",
                                  prop: "categoryName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入类别名称",
                                    maxlength: "40",
                                  },
                                  model: {
                                    value: _vm.form.categoryName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "categoryName", $$v)
                                    },
                                    expression: "form.categoryName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "门店排序", prop: "sortNo" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入门店排序",
                                    maxlength: "30",
                                  },
                                  model: {
                                    value: _vm.form.sortNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "sortNo", _vm._n($$v))
                                    },
                                    expression: "form.sortNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "零售可见",
                                  prop: "isPosVisible",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.form.isPosVisible,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "isPosVisible", $$v)
                                      },
                                      expression: "form.isPosVisible",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dict.type.App_is_pos_visible,
                                    function (dict) {
                                      return _c(
                                        "el-radio",
                                        {
                                          key: dict.value,
                                          attrs: { label: dict.value },
                                        },
                                        [_vm._v(_vm._s(dict.label) + " ")]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 20 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注", prop: "remark" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    type: "textarea",
                                    placeholder:
                                      "备注长度介于 1 和 80 字符之间",
                                    maxlength: "80",
                                    autosize: { minRows: 2, maxRows: 4 },
                                  },
                                  model: {
                                    value: _vm.form.remark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "remark", $$v)
                                    },
                                    expression: "form.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }