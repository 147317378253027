var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "machiningDetail" },
    [
      _c("c-dialog", {
        attrs: {
          levelType: true,
          title: "加值属性",
          width: 1000,
          showDialog: _vm.open,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.open = $event
          },
          "update:show-dialog": function ($event) {
            _vm.open = $event
          },
          close: _vm.handleClose,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c("cardTitleCom", {
                      attrs: { cardTitle: "属性项信息" },
                      scopedSlots: _vm._u([
                        {
                          key: "cardContent",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "main",
                                  staticStyle: { padding: "10px" },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "x-f",
                                      attrs: {
                                        label: "属性组名称",
                                        prop: "machiningGroupName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入属性组名称",
                                        },
                                        model: {
                                          value: _vm.form.machiningGroupName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "machiningGroupName",
                                              $$v
                                            )
                                          },
                                          expression: "form.machiningGroupName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "属性值多选",
                                        prop: "isEnableCheckBox",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          model: {
                                            value: _vm.form.isEnableCheckBox,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "isEnableCheckBox",
                                                $$v
                                              )
                                            },
                                            expression: "form.isEnableCheckBox",
                                          },
                                        },
                                        [_vm._v("启用")]
                                      ),
                                      _c("div", { staticClass: "tag" }, [
                                        _vm._v(
                                          " 该属性支持选择多个属性值,比如一杯奶茶可以添加布丁,珍珠等多种配料. "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "属性值必选",
                                        prop: "isRequired",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          model: {
                                            value: _vm.form.isRequired,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "isRequired",
                                                $$v
                                              )
                                            },
                                            expression: "form.isRequired",
                                          },
                                        },
                                        [_vm._v("启用")]
                                      ),
                                      _c("div", { staticClass: "tag" }, [
                                        _vm._v(
                                          "启用后,该属性为用户下单必选内容."
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c("cardTitleCom", {
                      attrs: { cardTitle: "属性列表" },
                      scopedSlots: _vm._u([
                        {
                          key: "leftCardTitle",
                          fn: function () {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: { click: _vm.huiShou },
                                },
                                [_vm._v("回收站")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "cardContent",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticStyle: { padding: "10px" } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      ref: "Table",
                                      staticClass: "table",
                                      attrs: {
                                        data: _vm.form.machiningItems,
                                        "row-key": "columnId",
                                        "max-height": "300",
                                        border: "",
                                      },
                                      on: {
                                        "cell-mouse-enter": _vm.cellMouseEnter,
                                        "cell-mouse-leave": _vm.cellMouseLeave,
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: _vm.tableCellLabel,
                                          width: "80",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                scope.row.hoverRow
                                                  ? _c("i", {
                                                      staticClass:
                                                        "el-icon-circle-plus operatePush",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleRow(
                                                            "push",
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                scope.row.hoverRow
                                                  ? _c("i", {
                                                      staticClass:
                                                        "el-icon-remove operateDel",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleRow(
                                                            "del",
                                                            scope.$index
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                !scope.row.hoverRow
                                                  ? _c("div", [
                                                      _vm._v(
                                                        _vm._s(scope.$index + 1)
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                      _vm._l(_vm.tableField, function (item) {
                                        return _c(
                                          "el-table-column",
                                          {
                                            key: item.prop,
                                            attrs: {
                                              label: item.label,
                                              align: "center",
                                              prop: item.prop,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop:
                                                              "machiningItems[" +
                                                              scope.$index +
                                                              "]." +
                                                              item.prop,
                                                            rules:
                                                              _vm.rules[
                                                                "machiningItems." +
                                                                  item.prop
                                                              ],
                                                          },
                                                        },
                                                        [
                                                          item.type == "input"
                                                            ? _c("el-input", {
                                                                model: {
                                                                  value:
                                                                    scope.row[
                                                                      "" +
                                                                        item.prop
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        scope.row,
                                                                        "" +
                                                                          item.prop,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "scope.row[`${item.prop}`]",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          item.type == "check"
                                                            ? _c(
                                                                "el-checkbox",
                                                                {
                                                                  model: {
                                                                    value:
                                                                      scope.row[
                                                                        "" +
                                                                          item.prop
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          scope.row,
                                                                          "" +
                                                                            item.prop,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "scope.row[`${item.prop}`]",
                                                                  },
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            item.prop === "machiningName"
                                              ? _c(
                                                  "template",
                                                  { slot: "header" },
                                                  [
                                                    _c(
                                                      "i",
                                                      {
                                                        staticStyle: {
                                                          color: "#ff4949",
                                                        },
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.label) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.handleClose } },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.submitForm },
                  },
                  [_vm._v("确定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog2", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }