var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    getList: _vm.getList,
                    isBillDate: false,
                    dropDownList: _vm.dropDownList,
                    isSearchInput: true,
                    isSearchInputTitle: "商品编码、名称、条码",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "marT10" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("商品类别"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({ key: "listCategory" }).option,
                          },
                          model: {
                            value: _vm.queryParams.categoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "categoryId", $$v)
                            },
                            expression: "queryParams.categoryId",
                          },
                        }),
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("仓库"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({ key: "listStore" }).option,
                          },
                          model: {
                            value: _vm.queryParams.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "storeId", $$v)
                            },
                            expression: "queryParams.storeId",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        { staticClass: "goodsInfo", attrs: { cardTitle: "商品信息" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c("operatingButton", {
                  attrs: {
                    getList: _vm.getList,
                    isAddBtn: false,
                    isDeleteBtn: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "specialDiyBtn",
                      fn: function () {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-plus",
                                size: "mini",
                                disabled: _vm.isAlreadyInitial,
                              },
                              on: { click: _vm.handleAdd },
                            },
                            [_vm._v(" 新增 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-s-check",
                                size: "mini",
                                disabled: _vm.isAlreadyInitial,
                              },
                              on: { click: _vm.getOpeningAccount },
                            },
                            [_vm._v("期初建账 ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                icon: "el-icon-delete",
                                disabled: _vm.multiple || _vm.isAlreadyInitial,
                                size: "mini",
                              },
                              on: { click: _vm.handleDelete },
                            },
                            [_vm._v("删除 ")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingTable,
                        expression: "loadingTable",
                      },
                    ],
                    ref: "multipleTable",
                    attrs: {
                      "tooltip-effect": "dark",
                      data: _vm.tableData,
                      border: "",
                      "max-height": _vm.tableHeight,
                      height: _vm.tableHeight,
                    },
                    on: {
                      "selection-change": _vm.handleSelectionChange,
                      "row-click": _vm.handleRowClick,
                    },
                  },
                  [
                    !_vm.isAlreadyInitial
                      ? _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "50",
                            align: "center",
                          },
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        align: "center",
                        prop: "userId",
                        width: "80",
                        type: "index",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "商品编码",
                        align: "center",
                        prop: "goodsNo",
                        width: "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "商品名称",
                        align: "center",
                        prop: "goodsName",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "商品类别",
                        align: "center",
                        prop: "categoryName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "商品条码",
                        align: "center",
                        prop: "barcode",
                        width: "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "规格",
                        align: "center",
                        prop: "goodsSpec",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "单位",
                        align: "center",
                        prop: "unitName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "仓库",
                        align: "center",
                        prop: "storeName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "数量",
                        align: "center",
                        prop: "unitFirstQty",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "成本",
                        align: "center",
                        prop: "unitFirstPrice",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "期初总金额",
                        align: "center",
                        prop: "billMoney",
                      },
                    }),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    isSelectAll: !_vm.isAlreadyInitial,
                    idsCom: _vm.ids,
                    totalCom: _vm.total,
                  },
                  on: { selectAllCom: _vm.selectAll },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }