<!-- 营养物质弹窗 -->
<template>
  <div>
    <!-- 搭配营养物质弹窗 -->
    <el-dialog
      v-dialogDrag
      title=""
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column
          property="date"
          label="操作"
          width="120px"
          align="center"
        >
          <template v-slot="scope">
            <i
              @click="rowAvailable('push')"
              class="el-icon-circle-plus operatePush"
              :class="{ disabled: tableData.length >= 20 }"
            ></i>
            <i
              @click="clearLastRowDataAge(scope.$index)"
              class="el-icon-remove operateDel"
              :class="{ disabled: tableData.length === 1 }"
            ></i>
          </template>
        </el-table-column>

        <el-table-column
          prop="nutrientName"
          label="营养物质"
          width="280px"
          align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.nutrientName"
              width="120px"
              @input="scope.row.isChanged = true"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="nutrientUnit"
          label="单位"
          align="center"
          @input="scope.row.isChanged = true"
        >
          <template slot-scope="scope">
            <el-input v-model="scope.row.nutrientUnit" width="40px"> </el-input>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateGoodNutrient">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "yywz",
  data() {
    return {
      isDataSelected: false, //是否选中数据
      multipleSelection: [], //选中营养物质弹窗
      dialogVisible: false, //弹窗开关
      tableData: [{}],
      curSpecIndex: undefined,
    };
    },
    methods: {
        handleSelectionChange(val) {
            this.$set(
                this.goodsUnit[this.curSpecIndex],
                "goodsUnitNutrientReqList",
                val
            );
            this.multipleSelection = val;
            // 检查是否有数据被选中
            this.isDataSelected = val.length > 0;
        },
        async updateGoodNutrient() {
            if (!this.isDataSelected) {
                // 如果没有数据被选中，显示提示信息
                this.$message.error("未选中，请选至少一条数据");
                return;
            }
            // 判断是否有需要更新的数据
            let hasUpdates = false;
            // 检查是否有新增或修改的数据，没有就不发请求
            for (const row of this.tableData) {
                if (row.isChanged) {
                    hasUpdates = true;
                    break;
                }
            }

            // 如果没有需要更新的数据，直接关闭对话框
            if (!hasUpdates) {
                this.dialogVisible = false;
                return;
            }

            // 将新增和修改的数据发送到后端
            let updateReqList = this.tableData
                .map((v) => {
                    if (v.nutrientId) {
                        return v;
                    }
                })
                .filter((item) => item !== null && item !== undefined);
            let addReqList = this.tableData
                .map((v) => {
                    if (!v.nutrientId) {
                        return v;
                    }
                })
                .filter((item) => item !== null && item !== undefined);
            const requestData = {
                addReqList,
                updateReqList,
                nutrientId: 0, // 根据需要，您可能需要提供其他参数
                nutrientIds: [],
                nutrientName: "",
                nutrientUnit: "",
            };
            try {
                const res = await updateNutrientAPI(requestData);
                if (res.code === 200) {
                    this.dialogVisible = false;
                    this.$message.success("保存成功");
                }
            } catch (error) {
                this.$message.error("保存失败，发生异常，请检查网络连接");
            }
        },
        async openNutrients(index) {
            this.curSpecIndex = index;
            this.dialogVisible = true;
            const res2 = await nutrientListAPI();
            this.tableData = res2.data.map((row) => ({
                ...row,
                isChanged: false, // 初始化为未更改状态
            }));
            if (this.tableData.length === 0) {
                this.tableData = [{}]; // 添加一个空对象作为新的最后一行
            }
        },
        handleClose() {
            this.dialogVisible = false;
        },
        rowAvailable(action, index) {
            if (action === "push" && this.tableData.length < 20) {
                const newRow = {
                    nutrientId: 0, // 新增的数据通常没有ID，根据后端要求填写默认值
                    nutrientIds: [],
                    nutrientName: "",
                    nutrientUnit: "",
                    updateReqList: [],
                };
                this.tableData.push(newRow);
            } else if (action === "del" && this.tableData.length > 1) {
                this.tableData.splice(index, 1)[0];
            }
        },
        //删除营养成分弹窗表格
        async clearLastRowDataAge(index) {
            if (this.tableData.length === 1) {
                const lastRow = this.tableData[0];
                if (lastRow.nutrientId !== 0) {
                    try {
                        const deleteRes = await deleteNutrientAPI({
                            nutrientIds: [lastRow.nutrientId],
                        });
                        if (deleteRes.code === 200) {
                            this.$message.success("删除成功");
                        }
                    } catch (error) {
                        this.$message.error("删除失败，发生异常，请检查网络连接");
                    }
                } else {
                    this.$message.error("空数据，无法删除");
                }

                // 清空最后一行数据
                for (const key in lastRow) {
                    if (Object.hasOwnProperty.call(lastRow, key)) {
                        lastRow[key] = null;
                    }
                }
            } else if (this.tableData.length > 1) {
                const deletedRow = this.tableData.splice(index, 1)[0];
                if (deletedRow.nutrientId !== 0) {
                    try {
                        const deleteRes = await deleteNutrientAPI({
                            nutrientIds: [deletedRow.nutrientId],
                        });
                        if (deleteRes.code === 200) {
                            this.$message.success("删除成功");
                        }
                    } catch (error) {
                        // this.$message.error("删除失败，发生异常，请检查网络连接");
                    }
                }
            }
            // 检查是否需要添加一个空行
            if (this.tableData.length === 0) {
                this.tableData.push({}); // 添加一个空对象作为新的最后一行
            }
        },
    }
};
</script>

<style lang="scss" scoped></style>
