<template>
  <div>
    <TablePage
      ref="tablePage"
      class="tablepage"
      v-model="options"
      @handleEvent="handleEvent"
    >
    </TablePage>
    <c-dialog :title="title" :width="500" :showDialog.sync="open">
      <template #content>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="80px"
          v-loading="loadingDia"
        >
          <el-row>
            <el-col :span="20">
              <el-form-item label="上级类别" prop="parentId">
                <treeselect
                  v-model="form.parentId"
                  :options="categoryTreeOptionsUnclassified"
                  :show-count="true"
                  placeholder="请选择上级类别"
                  :normalizer="normalizer"
                  :open="getTreeselect"
                  @input="changeParentId"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="类别编码" prop="categoryNo">
                <div class="x-fc">
                  <el-input
                    v-show="form.parentId != 0"
                    v-model="form.categoryNo1"
                    placeholder="请选择上级类别"
                    disabled
                  />
                  <el-input
                    v-model="form.categoryNo"
                    placeholder="请输入类别编码"
                    maxlength="13"
                  />
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="类别名称" prop="categoryName">
                <el-input
                  v-model="form.categoryName"
                  placeholder="请输入类别名称"
                  maxlength="40"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="门店排序" prop="sortNo">
                <el-input
                  v-model.number="form.sortNo"
                  placeholder="请输入门店排序"
                  maxlength="30"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="零售可见" prop="isPosVisible">
                <el-radio-group v-model="form.isPosVisible">
                  <el-radio
                    v-for="dict in dict.type.App_is_pos_visible"
                    :key="dict.value"
                    :label="dict.value"
                    >{{ dict.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="20">
              <el-form-item label="备注" prop="remark">
                <el-input
                  size="mini"
                  v-model="form.remark"
                  type="textarea"
                  placeholder="备注长度介于 1 和 80 字符之间"
                  maxlength="80"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import TablePage from '@/components/tablePage'
import CDialog from '@/components/dialogTemplate/dialog/index.vue' //弹窗
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Treeselect from '@riophae/vue-treeselect'
import {
  listCategoryTree,
  listCategory,
  addCategory,
  CategoryDetail,
  updateCategory,
  delCategory,
  getGoodsCategoryAutoNo,
  restoreGoodsCategory
} from '@/api/goods/category'
export default {
  name: 'Category',
  dicts: ['App_is_pos_visible'],
  components: {
    TablePage,
    CDialog,
    Treeselect
  },
  data () {
    return {
      options: {
        mutiSelect: true, // 多选
        selectable: row => row.categoryNo != '00',
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        tree: {
          title: '商品类别',
          getListApi: listCategoryTree
        },
        getListApi: listCategory,
        body: {},
        defaultBody: {},
        title: '商品类别',
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'categoryId',
        //搜索
        search: [
          {
            type: 'filters',
            tip: '类别编码/类别名称',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'categoryNos', label: '类别编码' },
              { filter: 'categoryNames', label: '类别名称' }
            ]
          },
          {
            type: 'button',
            tip: '查询',
            btnType: 'primary',
            click: 'search',
            isRestore: true
          }
        ],
        //按钮
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary'
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            alertText: '确认要删除选中商品类别吗？',
            btnType: 'dropdown',
            trigger: 'click', // 要求
            other: [
              {
                label: '回收站',
                collectionDialog: {
                  restoreListApi: restoreGoodsCategory,
                  dcollectionBody: {
                    delFlag: '1'
                  }
                }
              }
            ]
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'categoryNo',
            label: '类别编码',
            minWidth: 180
          },
          {
            prop: 'categoryName',
            label: '类别名称',
            minWidth: 180
          },
          {
            prop: 'sortNo',
            label: '门店排序',
            minWidth: 120
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 120
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 120
          },
          {
            prop: 'updateTime',
            label: '修改时间',
            minWidth: 155
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 120
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 155
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'add':
          this.title = '新增仓库'
          this.dialogFormVisible = true
          this.dialogLoading = true
          this.reset()
          this.form.produceDeptId = this.options?.tree?.body?.produceDeptId
          this.dialogLoading = false
          break
        case 'update':
          this.title = '编辑生产工厂'
          this.dialogFormVisible = true
          this.dialogLoading = true
          this.reset()
          const res = await produceStoreDetail(row.lineId)
          this.form = res.data
          this.dialogLoading = false
          break
        case 'batchEdit':
          this.formBatch = {
            produceDeptId: undefined,
            storeType: undefined
          }
          this.dialogFormVisibleBatch = true
          break
        case 'del':
          this.handleDelete()
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
