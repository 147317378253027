var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-box" }, [
    _c(
      "div",
      {
        staticClass: "borderAll",
        attrs: { tabindex: "-1" },
        on: { focusout: _vm.handleBlur },
      },
      [
        _c("div", { staticClass: "bgcBox", on: { click: _vm.handleInput } }, [
          _c("div", {
            staticClass: "selectedBgc",
            style: { backgroundColor: _vm.selectedColor },
          }),
          _c("i", {
            class: [
              "public",
              !_vm.showDropDown ? "el-icon-arrow-down" : "el-icon-arrow-up",
            ],
          }),
        ]),
      ]
    ),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showDropDown,
            expression: "showDropDown",
          },
        ],
        ref: "clickLiList",
      },
      _vm._l(_vm.backgroundColorList, function (item, index) {
        return _c("li", {
          key: index,
          staticClass: "fontS14",
          class: { active: index === _vm.activeIndex },
          style: { backgroundColor: item.color },
          on: {
            click: function ($event) {
              return _vm.handleItemClick(item, index)
            },
          },
        })
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }