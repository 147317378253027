var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "goodsInfoDetail" },
    [
      _c("topOperatingButton", {
        attrs: {
          disabled: _vm.form.goodsNo == "000",
          isAuditBillBtn: false,
          id: "topOperatingButton",
        },
        on: {
          submitForm: _vm.submitForm,
          addBill: function ($event) {
            return _vm.submitForm(false)
          },
          getQuit: _vm.getQuit,
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.boxLoading,
              expression: "boxLoading",
            },
          ],
          staticClass: "main",
          attrs: { "element-loading-text": "数据加载中" },
        },
        [
          _c("comCard", {
            attrs: { title: "商品资料" },
            scopedSlots: _vm._u([
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              model: _vm.form,
                              "label-width": "110px",
                              rules: _vm.rules,
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "商品图片", prop: "goodsUrl" },
                              },
                              [
                                _c("AccessoryUpload", {
                                  attrs: {
                                    title: "",
                                    limit: Number(6),
                                    listType: "picture-card",
                                    fileSize: 1,
                                    fileList: _vm.fileList,
                                  },
                                  on: {
                                    getFileItems: _vm.getGoodsUrls,
                                    delFileItems: _vm.delGoodsUrl,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品编码",
                                      prop: "goodsNo",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled:
                                          _vm.form.goodsNo == "000000" &&
                                          _vm.form.goodsId,
                                        placeholder: "请输入商品编码",
                                        maxlength: "20",
                                      },
                                      model: {
                                        value: _vm.form.goodsNo,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "goodsNo", $$v)
                                        },
                                        expression: "form.goodsNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品条码",
                                      prop: "barcode",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入商品条码",
                                        maxlength: "20",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.basicSpec(
                                            $event,
                                            "barcode"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.form.barcode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "barcode", $$v)
                                        },
                                        expression: "form.barcode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品名称",
                                      prop: "goodsName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入商品名称",
                                        maxlength: "40",
                                      },
                                      model: {
                                        value: _vm.form.goodsName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "goodsName", $$v)
                                        },
                                        expression: "form.goodsName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品类型",
                                      prop: "goodsType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请输入商品类型",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.$refs.form.validateField(
                                              "goodsType"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.form.goodsType,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "goodsType", $$v)
                                          },
                                          expression: "form.goodsType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dict.type.goods_type,
                                        function (dict) {
                                          return _c("el-option", {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.label,
                                              value: dict.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品类别",
                                      prop: "categoryId",
                                    },
                                  },
                                  [
                                    _c("treeselect", {
                                      attrs: {
                                        normalizer: _vm.normalizer,
                                        "element-loading-text": "数据加载中",
                                        options: _vm.categoryData,
                                        "show-count": true,
                                        placeholder: "请选择商品类别",
                                      },
                                      on: {
                                        blur: _vm.treeVerify,
                                        close: _vm.treeVerify,
                                        change: _vm.changeCategory,
                                      },
                                      model: {
                                        value: _vm.form.categoryId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "categoryId", $$v)
                                        },
                                        expression: "form.categoryId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "基本单位",
                                      prop: "unitName",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          placeholder: "请选择基本单位",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.basicSpec(
                                              $event,
                                              "unitName"
                                            )
                                          },
                                          focus: _vm.getGoodsUnitDictList,
                                        },
                                        model: {
                                          value: _vm.form.unitName,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "unitName", $$v)
                                          },
                                          expression: "form.unitName",
                                        },
                                      },
                                      _vm._l(_vm.unitList, function (item) {
                                        return _c("el-option", {
                                          key: item.dictCode,
                                          attrs: {
                                            label: item.dictLabel,
                                            value: item.dictLabel,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                    _c(
                                      "i",
                                      {
                                        staticClass: "el-icon-edit marL10",
                                        staticStyle: {
                                          color: "#85cafc",
                                          fontsize: "14px",
                                          fontweight: "700",
                                          cursor: "pointer",
                                        },
                                        on: { click: _vm.setUnit },
                                      },
                                      [_vm._v("新增单位")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品进价(元)",
                                      prop: "purPrice",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入商品进价(元)",
                                        type: "number",
                                        maxlength: "7",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.basicSpec(
                                            $event,
                                            "purPrice"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.form.purPrice,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "purPrice", $$v)
                                        },
                                        expression: "form.purPrice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品售价(元)",
                                      prop: "salePrice",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入商品售价(元)",
                                        type: "number",
                                        maxlength: "7",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.basicSpec(
                                            $event,
                                            "salePrice"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.form.salePrice,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "salePrice", $$v)
                                        },
                                        expression: "form.salePrice",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "计价方式",
                                      prop: "pricingMode",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择计价方式",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.$refs.form.validateField(
                                              "pricingMode"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.form.pricingMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "pricingMode",
                                              $$v
                                            )
                                          },
                                          expression: "form.pricingMode",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dict.type.pricing_mode,
                                        function (dict) {
                                          return _c("el-option", {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.label,
                                              value: dict.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "是否管库存",
                                      prop: "isManageStock",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "x-f",
                                        staticStyle: { width: "70%" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "25%" },
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请选择是否管库存",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.$refs.form.validateField(
                                                  "isManageStock"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.form.isManageStock,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "isManageStock",
                                                  $$v
                                                )
                                              },
                                              expression: "form.isManageStock",
                                            },
                                          },
                                          _vm._l(
                                            _vm.dict.type.App_is_manage_stock,
                                            function (dict) {
                                              return _c("el-option", {
                                                key: dict.value,
                                                attrs: {
                                                  label: dict.label,
                                                  value: dict.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { margin: "0 10px" } },
                                          [_vm._v("仓库")]
                                        ),
                                        _c("SelectRemote", {
                                          staticClass: "flex1",
                                          attrs: {
                                            size: "medium",
                                            option: _vm.$select({
                                              key: "listStore",
                                            }).option,
                                          },
                                          model: {
                                            value: _vm.form.storeId,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "storeId", $$v)
                                            },
                                            expression: "form.storeId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "储存方式",
                                      prop: "depositWay",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入储存方式",
                                        maxlength: "40",
                                      },
                                      model: {
                                        value: _vm.form.depositWay,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "depositWay", $$v)
                                        },
                                        expression: "form.depositWay",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品品牌",
                                      prop: "brandId",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择商品品牌",
                                          "element-loading-text": "数据加载中",
                                        },
                                        model: {
                                          value: _vm.form.brandId,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "brandId", $$v)
                                          },
                                          expression: "form.brandId",
                                        },
                                      },
                                      _vm._l(_vm.brandData, function (item) {
                                        return _c("el-option", {
                                          key: item.brandId,
                                          attrs: {
                                            label: item.brandName,
                                            value: item.brandId,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品状态",
                                      prop: "status",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择商品状态",
                                        },
                                        model: {
                                          value: _vm.form.status,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "status", $$v)
                                          },
                                          expression: "form.status",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "正常", value: 0 },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "停用", value: 1 },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品排序",
                                      prop: "sortNo",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入商品排序",
                                        type: "number",
                                        maxlength: "7",
                                      },
                                      model: {
                                        value: _vm.form.sortNo,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "sortNo", $$v)
                                        },
                                        expression: "form.sortNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "税率(%)",
                                      prop: "taxRate",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入税率(%)",
                                        type: "number",
                                        maxlength: "7",
                                      },
                                      model: {
                                        value: _vm.form.taxRate,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "taxRate", $$v)
                                        },
                                        expression: "form.taxRate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "保质期天数",
                                      prop: "qualityDays",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "x-f",
                                        staticStyle: { width: "70%" },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "35%" },
                                          attrs: {
                                            placeholder: "保质期天数",
                                            type: "number",
                                            maxlength: "7",
                                          },
                                          model: {
                                            value: _vm.form.qualityDays,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "qualityDays",
                                                $$v
                                              )
                                            },
                                            expression: "form.qualityDays",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          { staticStyle: { margin: "0 10px" } },
                                          [_vm._v("预警天数")]
                                        ),
                                        _c("el-input", {
                                          staticClass: "flex1",
                                          attrs: {
                                            placeholder: "预警天数",
                                            type: "number",
                                            maxlength: "7",
                                          },
                                          model: {
                                            value: _vm.form.qualityAlertDays,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "qualityAlertDays",
                                                $$v
                                              )
                                            },
                                            expression: "form.qualityAlertDays",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品重量(kg)",
                                      prop: "weight",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入商品重量(kg)",
                                        maxlength: "20",
                                      },
                                      model: {
                                        value: _vm.form.weight,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "weight", $$v)
                                        },
                                        expression: "form.weight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品型号",
                                      prop: "goodsModel",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入商品型号",
                                        maxlength: "20",
                                      },
                                      model: {
                                        value: _vm.form.goodsModel,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "goodsModel", $$v)
                                        },
                                        expression: "form.goodsModel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "商品规格",
                                      prop: "goodsSpec",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入商品规格",
                                        maxlength: "20",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.basicSpec(
                                            $event,
                                            "goodsSpec"
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.form.goodsSpec,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "goodsSpec", $$v)
                                        },
                                        expression: "form.goodsSpec",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "w33",
                                    attrs: {
                                      label: "供应商",
                                      prop: "partnerId",
                                    },
                                  },
                                  [
                                    _c("SelectRemote", {
                                      attrs: {
                                        option: _vm.$select({
                                          key: "listSupplier",
                                        }).option,
                                      },
                                      model: {
                                        value: _vm.form.partnerId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "partnerId", $$v)
                                        },
                                        expression: "form.partnerId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "x-f switch" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "允许改价",
                                      prop: "weight",
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value: _vm.form.isEnableBargaining,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isEnableBargaining",
                                            $$v
                                          )
                                        },
                                        expression: "form.isEnableBargaining",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "允许折扣",
                                      prop: "weight",
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value: _vm.form.isEnableDiscount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isEnableDiscount",
                                            $$v
                                          )
                                        },
                                        expression: "form.isEnableDiscount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "允许赠送",
                                      prop: "weight",
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value: _vm.form.isEnableGive,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isEnableGive",
                                            $$v
                                          )
                                        },
                                        expression: "form.isEnableGive",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "允许采购",
                                      prop: "weight",
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value: _vm.form.isEnablePurchase,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isEnablePurchase",
                                            $$v
                                          )
                                        },
                                        expression: "form.isEnablePurchase",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "允许销售",
                                      prop: "weight",
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value: _vm.form.isEnableSale,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isEnableSale",
                                            $$v
                                          )
                                        },
                                        expression: "form.isEnableSale",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "允许积分",
                                      prop: "weight",
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      model: {
                                        value: _vm.form.isEnableIntegral,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isEnableIntegral",
                                            $$v
                                          )
                                        },
                                        expression: "form.isEnableIntegral",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "specList",
                                attrs: { label: "规格列表", prop: "goodsUnit" },
                              },
                              [
                                _c("div", { staticClass: "goodsSpecList" }, [
                                  _c(
                                    "div",
                                    { staticClass: "specItem" },
                                    [
                                      _c("goodsSpecItem", {
                                        attrs: {
                                          goodsId: _vm.form.goodsId,
                                          goodsUnit: _vm.form.goodsUnit,
                                          machiningData:
                                            _vm.allMachiningGroupData,
                                          unitList: _vm.unitList,
                                        },
                                        on: {
                                          handleAddMachiningGroup:
                                            _vm.handleAddMachiningGroup,
                                          handleAddMachining:
                                            _vm.handleAddMachining,
                                          delGoodsSpec: _vm.handleDelGoodsSpec,
                                          setUnit: _vm.setUnit,
                                          openSelectGoods: _vm.openSelectGoods,
                                          handleCollocationTableRow:
                                            _vm.handleCollocationTableRow,
                                          handleNutrientTableRow:
                                            _vm.handleNutrientTableRow,
                                          collocationTableKeyup:
                                            _vm.collocationTableKeyup,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "btn marT10" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "el-icon-plus",
                                          attrs: { size: "mini" },
                                          on: { click: _vm.handleAddGoodsSpec },
                                        },
                                        [_vm._v("新建规格 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "w60",
                                attrs: { label: "商品备注", prop: "remark" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "备注长度介于 1 和 80 字符之间",
                                    maxlength: "80",
                                  },
                                  model: {
                                    value: _vm.form.remark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "remark", $$v)
                                    },
                                    expression: "form.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "w60",
                                attrs: { label: "商品备注2", prop: "remark2" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "备注长度介于 1 和 80 字符之间",
                                    maxlength: "80",
                                  },
                                  model: {
                                    value: _vm.form.remark2,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "remark2", $$v)
                                    },
                                    expression: "form.remark2",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("selectMachining", {
        attrs: {
          OpenMachining: _vm.OpenMachining,
          machiningData: _vm.machiningData,
        },
        on: {
          "update:OpenMachining": function ($event) {
            _vm.OpenMachining = $event
          },
          "update:open-machining": function ($event) {
            _vm.OpenMachining = $event
          },
          getMachining: _vm.pitchMachining,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          staticClass: "goodsUnit",
          attrs: {
            title: "商品单位设置",
            visible: _vm.OpenUnit,
            width: "30%",
            "lock-scroll": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.OpenUnit = $event
            },
          },
        },
        [
          _c("div", { staticClass: "top" }, [
            _c("div", { staticClass: "unitTotal" }, [
              _vm._v(" 共有"),
              _c("span", { staticClass: "num" }, [
                _vm._v(_vm._s(_vm.unitList.length)),
              ]),
              _vm._v("种单位 "),
            ]),
            _c("div", { staticClass: "addUnit" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.addUnit,
                    expression: "addUnit",
                  },
                ],
                staticClass: "ADDinput",
                attrs: { type: "text", placeholder: "请输入新单位" },
                domProps: { value: _vm.addUnit },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.addUnit = $event.target.value
                  },
                },
              }),
              _c(
                "span",
                {
                  staticClass: "ADDbtn",
                  on: {
                    click: function ($event) {
                      return _vm.setUnitList(_vm.addUnit)
                    },
                  },
                },
                [_vm._v("新增")]
              ),
            ]),
          ]),
          _c("div", { staticClass: "unitTable" }, [
            _c(
              "div",
              { staticClass: "table" },
              _vm._l(_vm.unitList, function (item, index) {
                return _c("div", { key: item.dictCode, staticClass: "item" }, [
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("el-input", {
                        ref: "unitFocus",
                        refInFor: true,
                        staticClass: "unit",
                        attrs: {
                          type: "text",
                          readonly: !(_vm.readonly === index),
                        },
                        model: {
                          value: item.dictValue,
                          callback: function ($$v) {
                            _vm.$set(item, "dictValue", $$v)
                          },
                          expression: "item.dictValue",
                        },
                      }),
                      _c("div", { staticClass: "set" }, [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showSave === index,
                              expression: "showSave === index",
                            },
                          ],
                          staticClass: "el-icon-circle-check",
                          on: {
                            click: function ($event) {
                              return _vm.updateUnit(item.dictCode, index)
                            },
                          },
                        }),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !(_vm.showSave === index),
                              expression: "!(showSave === index)",
                            },
                          ],
                          staticClass: "el-icon-edit",
                          on: {
                            click: function ($event) {
                              return _vm.unitGetFocus(index)
                            },
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
      _c("SelectGoods", {
        attrs: { OpenGoods: _vm.OpenGoods },
        on: {
          "update:OpenGoods": function ($event) {
            _vm.OpenGoods = $event
          },
          "update:open-goods": function ($event) {
            _vm.OpenGoods = $event
          },
          getGoodsIds: _vm.pitchGoods,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }