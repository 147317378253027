<template>
  <!-- 整体内容 -->
  <div class="wrap x-f" v-loading="loading" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard categoryTreeTitle="商品类别" @treeNodeId="getTreeNodeId" @appendTree="appendTree" @editTree="editTree"
      @removeTree="removeTree" :removeTreeIdArr="removeTreeIdArr" :selectTreeIdCom="selectTreeIdCom" :isEditBtn="true"
      :boxHeight="boxHeight" :boxInternalHeight="boxInternalHeight" :categoryTreeOptions="categoryTreeOptions" />
    <!-- 表格和搜索右边盒子 -->
    <div class="rightBox y-b">
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="查询" class="headGoodsSearch" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch v-model="queryParams" :getList="getList" :dropDownList="dropDownList"
              :isAdvancedSearch="false" :isBillDate="false" :isSearchInput="true" isSearchInputTitle="输入类别编码、名称" />
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="类别信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <!-- 操作按钮 -->
            <operatingButton :getList="getList" :isRecycleBtn="true" :isDeleteBtn="false" :multiple="multiple"
              @handleAdd="handleAdd" @handleDelete="handleDelete" @handleRedoDelete="dialogOptions.show = true" />
            <div>
              <el-table v-loading="loadingTable" :data="tableData" @selection-change="handleSelectionChange"
                @row-click="handleRowClick" border :max-height="tableHeight" :height="tableHeight" width="100%"
                ref="multipleTable">
                <el-table-column type="selection" width="50" align="center" :selectable="disableCheckbox" />
                <el-table-column type="index" width="50" align="center" label="序号" />
                <el-table-column label="类别编码" align="center" key="categoryNo" prop="categoryNo">
                  <template slot-scope="scope">
                    <el-link :disabled="scope.row.categoryNo == '00'" type="primary" @click="handleUpdate(scope.row)"
                      target="_blank">
                      {{ scope.row.categoryNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column label="类别名称" align="center" key="categoryName" prop="categoryName"
                  :show-overflow-tooltip="true" />
                <el-table-column label="零售可见" align="center" key="isPosVisible" prop="isPosVisible">
                  <template slot-scope="scope">
                    <span>{{scope.row.isPosVisible=='1'?'是':'否'}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="负库存销售" align="center" key="isCanMinusSale" prop="isCanMinusSale">
                  <template slot-scope="scope">
                    <span>{{scope.row.isCanMinusSale==0?'否':'是'}}</span>
                  </template>
                </el-table-column>
                <el-table-column label="门店排序" align="center" key="sortNo" prop="sortNo" sortable show-overflow-tooltip
                  width="110" />
                <el-table-column label="备注" align="center" key="remark" prop="remark" :show-overflow-tooltip="true" />
                <el-table-column label="修改人" align="center" key="updateBy" prop="updateBy"
                  :show-overflow-tooltip="true" />
                <el-table-column width="155" label="修改时间" align="center" prop="updateTime" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="创建人" align="center" key="createBy" prop="createBy"
                  :show-overflow-tooltip="true" />
                <el-table-column width="155" label="创建时间" align="center" prop="createTime" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
                @selectAllCom="selectAll" />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <c-dialog :title="title" :width="500" :showDialog.sync="open">
      <template #content>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" v-loading="loadingDia">
          <el-row>
            <el-col :span="20">
              <el-form-item label="上级类别" prop="parentId">
                <treeselect v-model="form.parentId" :options="categoryTreeOptionsUnclassified" :show-count="true"
                  placeholder="请选择上级类别" :normalizer="normalizer" :open="getTreeselect" @input="changeParentId" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="类别编码" prop="categoryNo">
                <div class="x-fc">
                  <el-input v-show="form.parentId != 0" v-model="form.categoryNo1" placeholder="请选择上级类别" disabled />
                  <el-input v-model="form.categoryNo" placeholder="请输入类别编码" maxlength="13" />
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="类别名称" prop="categoryName">
                <el-input v-model="form.categoryName" placeholder="请输入类别名称" maxlength="40" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="门店排序" prop="sortNo">
                <el-input v-model.number="form.sortNo" placeholder="请输入门店排序" maxlength="30" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="零售可见" prop="isPosVisible">
                <el-radio-group v-model="form.isPosVisible">
                  <el-radio v-for="dict in dict.type.App_is_pos_visible" :key="dict.value"
                    :label="dict.value">{{ dict.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label-width="90px" label="负库存销售" prop="isCanMinusSale">
                <el-radio-group v-model="form.isCanMinusSale">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="备注" prop="remark">
                <el-input size="mini" v-model="form.remark" type="textarea" placeholder="备注长度介于 1 和 80 字符之间"
                  maxlength="80" :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
    <!-- 回收站 -->
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import {
  listCategoryTree,
  listCategory,
  addCategory,
  CategoryDetail,
  updateCategory,
  delCategory,
  getGoodsCategoryAutoNo,
  restoreGoodsCategory
} from '@/api/goods/category'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import bottomPage from '@/views/components/bottomPage' //底部分页
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import Dialog2 from '@/components/Dialog' //回收站
export default {
  name: 'Category',
  dicts: ['App_is_pos_visible'],
  components: {
    Treeselect,
    cardTitleCom,
    categoryTreeCard,
    bottomPage,
    seniorSearch,
    operatingButton,
    CDialog,
    Dialog2
  },
  data() {
    return {
      removeTreeIdArr: undefined,
      isNotClassified: false,
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: listCategory,
          restoreListApi: restoreGoodsCategory,
          id: 'categoryId',
          search: [
            {
              type: 'filters',
              isRestore: true,
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'categoryNos', label: '类别编码' },
                { filter: 'categoryNames', label: '类别名称' }
              ]
            },
            {
              type: 'button',
              tip: '查询',
              btnType: 'primary',
              click: 'search',
              isRestore: true
            }
          ],
          columns: [
            {
              prop: 'categoryNo',
              label: '类别编码',
              minWidth: 180
            },
            {
              prop: 'categoryName',
              label: '类别名称',
              minWidth: 180
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 120
            },
            {
              prop: 'sortNo',
              label: '门店排序',
              minWidth: 120
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155
            }
          ]
        }
      },
      //选中的节点
      selectTreeIdCom: '',
      isParentIdDetail: false, //类别节流
      loadingDia: false,
      loadingTable: false,
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'categoryNos', name: '类别编码' },
        { parameter: 'categoryNames', name: '类别名称' }
      ],

      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      //选中数组的名称
      name: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 分类表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 分类树选项  有未分类
      categoryTreeOptions: [],
      // 分类树选项  没有未分类
      categoryTreeOptionsUnclassified: [],
      // 是否显示弹出层
      open: false,
      // 分类名称
      categoryName: undefined,
      //tree选中的id
      selectTreeId: undefined,
      // 表单参数
      form: {
        categoryNo: undefined,
        categoryName: undefined,
        parentId: undefined,
        isPosVisible: '1',
        isCanMinusSale: 1,
        sortNo: undefined,
        remark: undefined,
        categoryId: undefined,
        categoryNo1: undefined
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      // 分类导入参数
      upload: {
        // 是否显示弹出层（分类导入）
        open: false,
        // 弹出层标题（分类导入）
        title: '',
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的分类数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: 'Bearer ' + getToken() },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + '/system/user/importData'
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        categoryName: undefined,
        categoryNo: undefined,
        categoryd: undefined,
        delFlag: undefined
      },
      // 表单校验
      rules: {
        parentId: [
          {
            required: true,
            message: '上级类别不能为空',
            trigger: ['blur', 'change']
          }
        ],
        categoryName: [
          {
            required: true,
            message: '类别名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        categoryNo: [
          {
            required: true,
            message: '类别编号不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[a-zA-Z0-9]+$/,
            message: '只能输入数字和英文字符',
            trigger: ['blur', 'change']
          }
        ],
        sortNo: [{ type: 'number', message: '排序需为数字' }]
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 140
    },
    boxHeight() {
      return this.vivwH - 10
    },
    boxInternalHeight() {
      return this.vivwH - 83
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    this.getList()
    this.getTreeselect()
  },
  methods: {
    disableCheckbox(row) {
      return row.categoryNo != '00'
    },
    //还原刷新页面
    async handleEvent() {
      this.loading = true
      await this.getList()
      await this.getTreeselect()
      this.loading = false
    },
    async changeParentId(newVal) {
      if (this.isParentIdDetail) return
      if (newVal || newVal == 0) {
        this.loadingDia = true
        const { data } = await getGoodsCategoryAutoNo(newVal)
        this.form.categoryNo1 = data.parentNo
        this.form.categoryNo = data.autoNo
        this.loadingDia = false
      } else {
        this.form.categoryNo1 = ''
        this.form.categoryNo = ''
      }
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      if (row.categoryNo != '00') {
        this.$refs.multipleTable.toggleRowSelection(row)
      }
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await listCategory({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //点击类别树
    async getTreeNodeId(value) {
      this.queryParams.parentId = value
      await this.getList()
      if (this.categoryTreeOptions[0].children[0].id != value) {
        this.isNotClassified = false
        this.selectTreeId = value
      } else {
        this.isNotClassified = true
      }
    },
    //treeSelect结构属性转换
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },
    //新增Tree商品类别
    appendTree(data) {
      this.handleAdd(data)
    },
    //编辑Tree商品类别
    editTree(data) {
      this.title = '编辑类别'
      //   this.reset()
      const row = {}
      row.categoryId = data.id
      this.handleUpdate(row)
    },

    //删除Tree商品类别
    removeTree({ node, data }) {
      const row = {}
      row.categoryId = [data.id]
      row.label = data.label
      this.handleDelete(row)
    },

    /** 查询分类列表 */
    getList() {
      this.loadingTable = true
      listCategory(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loadingTable = false
      })
    },

    /** 查询分类下拉树结构 */
    getTreeselect() {
      listCategoryTree().then(response => {
        //有未分类
        this.categoryTreeOptions = JSON.parse(JSON.stringify(response.data))
        //没未分类
        if (response.data.length > 0) {
          response.data[0].children = response.data[0].children?.filter(
            v => v.number != '00'
          )
        }
        this.categoryTreeOptionsUnclassified = response.data
        if (this.selectTreeId) {
          this.selectTreeIdCom = this.selectTreeId
        }
      })
    },
    //递归过滤编号00
    filterTreeByCategoryNo(data, categoryNo) {
      return data?.filter(item => {
        if (item.number === categoryNo) {
          return false // 过滤掉当前节点
        }
        if (item.children && item.children.length > 0) {
          item.children = this.filterTreeByCategoryNo(item.number, categoryNo)
        }
        return true // 保留当前节点
      })
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        categoryNo: undefined,
        categoryName: undefined,
        parentId: undefined,
        isPosVisible: '1',
        isCanMinusSale: 1,
        sortNo: undefined,
        remark: undefined,
        categoryId: undefined
      }
      this.resetForm('form')
    },

    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.categoryId)
      this.name = selection.map(item => item.categoryName)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd(data) {
      if (this.isNotClassified)
        return this.$message.error('未分类下不能添加子级')
      this.reset()
      this.form.parentId = data?.id || this.selectTreeId
      this.open = true
      this.title = '新增类别'
      this.changeParentId(this.form.parentId)
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.loadingDia = true
      this.isParentIdDetail = true
      this.reset()
      const CategoryId = row.categoryId || this.ids[0]
      this.open = true
      CategoryDetail(CategoryId)
        .then(response => {
          this.form.parentId = response.data.parentId
          this.form.categoryId = response.data.categoryId
          this.form.categoryNo1 = response.data.parentNo
          this.form.categoryNo = response.data.categoryNo
          this.form.categoryName = response.data.categoryName
          this.form.isPosVisible = response.data.isPosVisible
          this.form.sortNo = response.data.sortNo
          this.form.remark = response.data.remark
          this.form.isCanMinusSale = response.data.isCanMinusSale
          if (this.form.isCanMinusSale == null) {
            this.form.isCanMinusSale = 1
          }
          this.title = '修改类别'
          this.loadingDia = false
        })
        .then(res => {
          this.isParentIdDetail = false
        })
        .catch(res => {
          this.isParentIdDetail = false
        })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.categoryId == this.form.parentId) {
            this.$message.error('父类不能和子类相同')
            return
          }
          this.loadingDia = true

          let obj = JSON.parse(JSON.stringify(this.form))
          obj.categoryNo = (obj.categoryNo1 || '') + (obj.categoryNo || '')
          if (obj.categoryId != undefined) {
            updateCategory(obj)
              .then(async response => {
                this.$modal.msgSuccess('修改成功')
                this.open = false
                await this.getTreeselect()
                await this.getList()
                this.loadingDia = false
              })
              .catch(error => {
                console.log('Error:', error)
                this.loadingDia = false
              })
          } else {
            addCategory(obj)
              .then(async response => {
                this.$modal.msgSuccess('新增成功')
                this.open = false
                await this.getTreeselect()
                await this.getList()
                this.loadingDia = false
              })
              .catch(error => {
                console.log('Error:', error)
                this.loadingDia = false
              })
          }
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const CategoryIds = row?.categoryId || this.ids || []
      this.$confirm(`确定删除所选的<span style="color: #1890ff;"> ${CategoryIds.length} </span>个类别吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      })
        .then(function () {
          return delCategory(CategoryIds)
        })
        .then(async () => {
          this.removeTreeIdArr = CategoryIds
          await this.getList()
          await this.getTreeselect()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => { })
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        '/api/system/goods/category/export',
        {
          ...this.queryParams
        },
        `user_${new Date().getTime()}.xlsx`
      )
    }
  }
}
</script>
<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 98px);
    width: calc(100% - 354px); //商品搜索条件
    .headGoodsSearch {
      width: 100%;
      // 搜索
      .navSearch {
        padding: 10px 10px 10px 20px;
        //筛选条件
        .searchCriteria {
          padding-top: 10px;
          .selectFrameUp {
            display: inline-block;
            margin-right: 15px;
          }
        }
      }
    }
    //商品表格卡片
    .headGoodsTable {
      height: calc(100% - 94px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
      }
    }
  }
}
</style>
