var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "价签简介" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "padd15" }, [
              _c("span", { staticClass: "fontS14" }, [
                _vm._v(
                  "价签用于展示商品的名称、规格、零售价等信息，常张贴在便利店、超市的货架上，方便用户了解商品。 目前仅支持打印门店商品价签。"
                ),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          style: { height: _vm.tableHeight + "px", marginBottom: 0 },
          attrs: { cardTitle: "模板信息" },
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c("operatingButton", {
                  attrs: { getList: _vm.getList, isDeleteBtn: false },
                  on: { handleAdd: _vm.handleAdd },
                }),
                _c(
                  "el-scrollbar",
                  {
                    style: {
                      height: _vm.tableHeight - 103 + "px",
                      marginBottom: 0,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingTable,
                            expression: "loadingTable",
                          },
                        ],
                        staticClass: "x-w",
                      },
                      _vm._l(_vm.tableData, function (item) {
                        return _c("div", { key: item.lableTemplateId }, [
                          _c("div", { staticClass: "templateContent" }, [
                            _c("div", { staticClass: "previewImg" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "printDesignPreview",
                                  style: {
                                    width: item.width + "mm",
                                    height: item.height + "mm",
                                    "background-image":
                                      "url(" + item.backgroundUrl + ")",
                                  },
                                },
                                _vm._l(
                                  item.lableTemplateContent.labelList,
                                  function (each, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "componentWrapper",
                                        style: {
                                          left: each.x + "px",
                                          top: each.y + "px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  each.tagType !=
                                                    "goodsBarcode" &&
                                                  each.tagType !=
                                                    "customText" &&
                                                  each.tagType != "qrCode",
                                                expression:
                                                  "\n                          each.tagType != 'goodsBarcode' &&\n                          each.tagType != 'customText' &&\n                          each.tagType != 'qrCode'\n                        ",
                                              },
                                            ],
                                            style: {
                                              fontSize: each.fontSize + "px",
                                              fontWeight: each.bold
                                                ? "bold"
                                                : "normal",
                                              textDecoration: each.underline
                                                ? "underline"
                                                : "none",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(each.printContent + " ###")
                                            ),
                                          ]
                                        ),
                                        each.tagType == "goodsBarcode"
                                          ? _c(
                                              "div",
                                              [
                                                _c("JsBarcode", {
                                                  attrs: {
                                                    value: "6901028071069",
                                                    displayValue:
                                                      each.barcodeType != 1,
                                                    width: each.barcodeWidth,
                                                    height: each.barcodeHeight,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  each.tagType == "customText",
                                                expression:
                                                  "each.tagType == 'customText'",
                                              },
                                            ],
                                            style: {
                                              fontSize: each.fontSize + "px",
                                              fontWeight: each.bold
                                                ? "bold"
                                                : "normal",
                                              textDecoration: each.underline
                                                ? "underline"
                                                : "none",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(each.customText) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("el-image", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: each.tagType == "qrCode",
                                              expression:
                                                "each.tagType == 'qrCode'",
                                            },
                                          ],
                                          style: {
                                            width: each.imageWidth + "mm",
                                            height: each.imageHeight + "mm",
                                          },
                                          attrs: {
                                            src:
                                              each.qrCodeUrl ||
                                              "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-22/8b326689866a48529f05bd1cfc50277b.png",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _c("div", { staticClass: "marB10 x-bc" }, [
                              _c("div", { staticClass: "omit templateName" }, [
                                _vm._v(
                                  " " + _vm._s(item.lableTemplateName) + " "
                                ),
                              ]),
                              _c("div", { staticClass: "isSystem" }, [
                                _vm._v("自定义"),
                              ]),
                            ]),
                            _c("div", { staticClass: "marB10 x-bc" }, [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.width + "*" + item.height + "mm"
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("div", [_vm._v(_vm._s(item.createTime))]),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "copyLine" },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { width: "33.3%" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handCopy(item.lableTemplateId)
                                    },
                                  },
                                },
                                [_vm._v("复制")]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "leftBorder",
                                  staticStyle: { width: "33.3%" },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handEdit(item.lableTemplateId)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-popconfirm",
                                {
                                  staticClass: "leftBorder",
                                  staticStyle: {
                                    width: "33.3%",
                                    "text-align": "center",
                                  },
                                  attrs: {
                                    "confirm-button-text": "确认",
                                    "cancel-button-text": "取消",
                                    icon: "el-icon-info",
                                    "icon-color": "red",
                                    title: "确定删除该模板吗？",
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.handDelete(
                                        item.lableTemplateId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }