<template>
  <div class="wrap" id="wrap">
    <!-- 简介 -->
    <cardTitleCom cardTitle="价签简介">
      <template slot="cardContent">
        <div class="padd15">
          <span class="fontS14">价签用于展示商品的名称、规格、零售价等信息，常张贴在便利店、超市的货架上，方便用户了解商品。
            目前仅支持打印门店商品价签。</span>
        </div>
      </template>
    </cardTitleCom>

    <!-- 模板信息 -->
    <cardTitleCom cardTitle="模板信息" :style="{ height: tableHeight + 'px', marginBottom: 0 }">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton :getList="getList" :isDeleteBtn="false" @handleAdd="handleAdd" />
          <el-scrollbar :style="{ height: tableHeight - 103 + 'px', marginBottom: 0 }">
            <div class="x-w" v-loading="loadingTable">
              <div v-for="item in tableData" :key="item.lableTemplateId">
                <div class="templateContent">
                  <!-- 标签样式 -->
                  <div class="previewImg">
                    <div class="printDesignPreview" :style="{
                        width: item.width + 'mm',
                        height: item.height + 'mm',
                        'background-image': `url(${item.backgroundUrl})`
                      }">
                      <div v-for="(each, index) in item.lableTemplateContent
                          .labelList" :key="index" class="componentWrapper" :style="{
                          left: each.x + 'px',
                          top: each.y + 'px'
                        }">
                        <span v-show="
                            each.tagType != 'goodsBarcode' &&
                            each.tagType != 'customText' &&
                            each.tagType != 'qrCode'
                          " :style="{
                            fontSize: each.fontSize + 'px',
                            fontWeight: each.bold ? 'bold' : 'normal',
                            textDecoration: each.underline
                              ? 'underline'
                              : 'none'
                          }">{{ each.printContent + ' ###' }}</span>
                        <!-- 条形码 -->
                        <div v-if="each.tagType == 'goodsBarcode'">
                          <JsBarcode value="6901028071069" :displayValue="each.barcodeType != 1"
                            :width="each.barcodeWidth" :height="each.barcodeHeight" />
                        </div>
                        <!-- 自定义文字 -->
                        <span v-show="each.tagType == 'customText'" :style="{
                            fontSize: each.fontSize + 'px',
                            fontWeight: each.bold ? 'bold' : 'normal',
                            textDecoration: each.underline
                              ? 'underline'
                              : 'none'
                          }">
                          {{ each.customText }}
                        </span>
                        <!-- 二维码 -->
                        <el-image v-show="each.tagType == 'qrCode'" :style="{
                            width: each.imageWidth + 'mm',
                            height: each.imageHeight + 'mm'
                          }"
                          :src="each.qrCodeUrl||'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-05-22/8b326689866a48529f05bd1cfc50277b.png'" />
                      </div>
                    </div>
                  </div>
                  <!-- 第一行 -->
                  <div class="marB10 x-bc">
                    <div class="omit templateName">
                      {{ item.lableTemplateName }}
                    </div>
                    <div class="isSystem">自定义</div>
                  </div>
                  <!-- 第二行 -->
                  <div class="marB10 x-bc">
                    <div>
                      {{ item.width + '*' + item.height + 'mm' }}
                    </div>
                    <div>{{ item.createTime }}</div>
                  </div>
                </div>
                <!-- 第三行 -->
                <div class="copyLine">
                  <el-button type="text" style="width: 33.3%" @click="handCopy(item.lableTemplateId)">复制</el-button>
                  <el-button type="text" style="width: 33.3%" class="leftBorder"
                    @click="handEdit(item.lableTemplateId)">编辑</el-button>
                  <el-popconfirm style="width: 33.3%; text-align: center" class="leftBorder" confirm-button-text="确认"
                    cancel-button-text="取消" icon="el-icon-info" icon-color="red" title="确定删除该模板吗？"
                    @confirm="handDelete(item.lableTemplateId)">
                    <el-button type="text" slot="reference">删除</el-button>
                  </el-popconfirm>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  labelTemplateListAPI,
  labelTemplateDeleteAPI,
  labelTemplateCopyAPI
} from '@/api/system/goods/labelTemplate' //模板
import operatingButton from '@/views/components/operatingButton' //操作按钮
import JsBarcode from '@/views/components/jsbarcode'
export default {
  name: 'labelTemplate',
  components: {
    JsBarcode,
    cardTitleCom,
    operatingButton
  },
  data() {
    return {
      loadingTable: false, //表格加载
      tableData: [], //表格数据
      vivwH: 0 //页面高度
    }
  },
  async created() {
    await this.getList()
  },
  computed: {
    tableHeight() {
      return this.vivwH - 106
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    //复制
    async handCopy(id) {
      await labelTemplateCopyAPI(id)
      await this.getList()
      this.$message({
        message: '复制成功',
        type: 'success'
      })
    },
    //删除
    async handDelete(id) {
      await labelTemplateDeleteAPI([id])
      await this.getList()
      this.$message({
        message: '删除成功',
        type: 'success'
      })
    },
    //详情
    handEdit(id) {
      this.$router.push({
        name: 'labelTemplateDetail',
        query: {
          lableTemplateId: id,
          type: 'Update'
        }
      })
    },
    //渲染列表
    async getList() {
      this.loadingTable = true
      const res = await labelTemplateListAPI()
      this.tableData = res
      if (this.tableData.length > 0) {
        this.tableData.forEach(item => {
          const newObj = JSON.parse(item.lableTemplateContent)
          this.$set(item, 'lableTemplateContent', newObj)
        })
      }
      console.log(' this.tableData', this.tableData)
      this.loadingTable = false
    },
    //新增单据事件
    handleAdd() {
      this.$router.push({
        name: 'labelTemplateDetail'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .tableContent {
    margin: 6px 10px 10px 10px;
    //模版内容
    .templateContent {
      position: relative;
      width: 290px;
      padding: 15px 15px 1px;
      background: #f7f8fa;
      border: 1px solid #dcdee0;
      border-bottom: 0px;
      box-sizing: border-box;
      border-radius: 2px;
      margin-left: 20px;
      font-size: 14px;
      //背景
      .previewImg {
        background: #fff;
        // height: 140px;
        height: 40mm;
        width: 60mm;
        margin-left: 15px;
        margin-bottom: 15px;
        overflow: hidden;
        .printDesignPreview {
          // transform: scale(0.5, 0.6);
          transform-origin: left top;
          display: flex;
          background-size: cover;
          background-repeat: no-repeat;
          border: 1px solid #ebedf0;
          position: relative;
          overflow: hidden;

          .componentWrapper {
            position: absolute;
            line-height: 1;
          }
        }
      }
      //模版名字
      .templateName {
        width: calc(100% - 52px);
      }
      //是否系统
      .isSystem {
        color: rgb(23, 101, 217);
        background-color: rgb(237, 244, 255);
        border: 1px solid rgb(84, 135, 223);
        line-height: 18px;
        height: 18px;
        box-sizing: border-box;
        padding: 0 4px;
        min-width: auto;
        border-radius: 2px;
      }
    }
    //复制
    .copyLine {
      border: 1px solid #dcdee0;
      border-top: 0px;
      margin-left: 20px;
      padding-right: 15px;
      width: 290px;
      margin-bottom: 20px;
      height: 35px;
      background: #fff;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      justify-content: space-around;
      //编辑和删除的左边框
      .leftBorder {
        border-left: 1px solid #ebedf0;
      }
    }
  }
}
::v-deep ::-webkit-scrollbar-track {
  background: transparent;
}
</style>
