<template>
  <div class="search-box">
    <div class="borderAll" tabindex="-1" @focusout="handleBlur">
      <div class="bgcBox" @click="handleInput">
        <div
          class="selectedBgc"
          :style="{ backgroundColor: selectedColor }"
        ></div>
        <i
          :class="[
            'public',
            !showDropDown ? 'el-icon-arrow-down' : 'el-icon-arrow-up'
          ]"
        ></i>
      </div>
    </div>
    <ul v-show="showDropDown" ref="clickLiList">
      <li
        :class="{ active: index === activeIndex }"
        class="fontS14"
        v-for="(item, index) in backgroundColorList"
        :key="index"
        :style="{ backgroundColor: item.color }"
        @click="handleItemClick(item, index)"
      ></li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    //清空输入框
    resetInputText: {
      type: Boolean,
      default: false
    },
    //单据日期
    dropDownList: {
      type: Array,
      default: function () {
        return [{ parameter: 'query', name: '全部' }]
      }
    }
  },
  data () {
    return {
      //背景颜色
      backgroundColorList: [
        { bgcName: 'Color010', color: '#63b359' },
        { bgcName: 'Color020', color: '#2c9f67' },
        { bgcName: 'Color030', color: '#509fc9' },
        { bgcName: 'Color040', color: '#5885cf' },
        { bgcName: 'Color050', color: '#9062c0' },
        { bgcName: 'Color060', color: '#d09a45' },
        { bgcName: 'Color070', color: '#e4b138' },
        { bgcName: 'Color080', color: '#ee903c' },
        { bgcName: 'Color081', color: '#f08500' },
        { bgcName: 'Color082', color: '#a9d92d' },
        { bgcName: 'Color090', color: '#dd6549' },
        { bgcName: 'Color100', color: '#cc463d' },
        { bgcName: 'Color101', color: '#cf3e36' },
        { bgcName: 'Color102', color: '#5E6671' }
      ],
      //输入框选中背景颜色
      selectedColor: '',
      //判断是否点击的是li
      isLiDom: false,
      showDropDown: false,
      //li点击
      activeIndex: -1
    }
  },
  methods: {
    //输入框点击
    handleInput () {
      this.showDropDown = !this.showDropDown
    },
    handleBlur () {
      console.log(123)
      // 获取DOM元素
      const listElem = this.$refs.clickLiList
      // 绑定事件监听器
      //   console.time()
      listElem.addEventListener('mousedown', event => {
        const target = event.target
        if (target.tagName.toLowerCase() === 'li') {
          this.isLiDom = true
        }
      })
      setTimeout(() => {
        //不是li元素关闭弹窗
        if (!this.isLiDom) {
          this.showDropDown = false
        } else {
          this.isLiDom = false
        }
      }, 100)
    },
    //点击事件
    handleItemClick (itemObj, index) {
      //输入框颜色
      this.selectedColor = itemObj.color
      //li选中
      this.activeIndex = index
      //传递名称
      this.$emit('wxColorName', itemObj.bgcName)
      //关闭下拉标签
      this.showDropDown = false
    }
  }
}
</script>
<style lang="scss" scoped>
.search-box {
  width: 240px;
  //   position: relative;
  //搜索框
  .borderAll {
    width: 240px;
    height: 28px;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 2px;
    //背景盒子
    .bgcBox {
      width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      cursor: pointer;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      //选中背景色
      .selectedBgc {
        width: 56px;
        height: 16px;
        margin-left: 8px;
        border-radius: 2px;
        border: 1px dotted #dadada;
      }
      //下拉
      //   .font {
      //     -webkit-transform: rotate(180deg);
      //     -ms-transform: rotate(180deg);
      //     transform: rotate(180deg);
      //     -webkit-transition: all 0.3s ease;
      //     -o-transition: all 0.3s ease;
      //     transition: all 0.3s ease;
      //   }
      //图标公共样式
      .public {
        margin-right: 4px;
        cursor: pointer;
        color: #ccc;
        font-size: 16px;
      }
    }
  }
  .borderAll:hover {
    border: 1px solid #2386ee;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 240px;
    margin: 0;
    padding: 0;
    list-style: none;
    // max-height: 250px;
    min-width: 220px;
    // overflow-y: auto;
    // overflow-x: hidden;
    position: fixed;
    z-index: 2005;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding-left: 10px;
    margin-top: 3px;
  }

  li {
    width: 36px;
    height: 16px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    margin: 4px 8px 4px 0;
    cursor: pointer;
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px dotted #dadada;
    // width: 240px;
    // padding: 5px;
    // cursor: pointer;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
  }
  li.active {
    border: 2px solid #000;
  }
  li:hover {
    background: #f2f2f2;
  }
}
</style>
