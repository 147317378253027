<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            v-model="queryParams"
            :getList="getList"
            :isBillDate="false"
            :dropDownList="dropDownList"
            :isSearchInput="true"
            isSearchInputTitle="商品编码、名称、条码"
            @isShowHighCom="getIsShowHigh"
          />
          <div v-show="showHigh">
            <div class="marT10">
              <span class="fS14MR10">商品类别</span>
              <SelectRemote
                class="marR15"
                v-model="queryParams.categoryId"
                style="width: 250px"
                :option="$select({ key: 'listCategory' }).option"
              />
              <span class="fS14MR10">仓库</span>
              <SelectRemote
                class="marR15"
                v-model="queryParams.storeId"
                style="width: 250px"
                :option="$select({ key: 'listStore' }).option"
              />
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="商品信息" class="goodsInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :getList="getList"
            :isAddBtn="false"
            :isDeleteBtn="false"
          >
            <template #specialDiyBtn>
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                :disabled="isAlreadyInitial"
                @click="handleAdd"
              >
                新增
              </el-button>
              <el-button
                type="primary"
                icon="el-icon-s-check"
                size="mini"
                :disabled="isAlreadyInitial"
                @click="getOpeningAccount"
                >期初建账
              </el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                :disabled="multiple || isAlreadyInitial"
                @click="handleDelete"
                size="mini"
                >删除
              </el-button></template
            >
          </operatingButton>
          <el-table
            v-loading="loadingTable"
            ref="multipleTable"
            tooltip-effect="dark"
            :data="tableData"
            border
            :max-height="tableHeight"
            :height="tableHeight"
            @selection-change="handleSelectionChange"
            @row-click="handleRowClick"
          >
            <el-table-column
              v-if="!isAlreadyInitial"
              type="selection"
              width="50"
              align="center"
            />
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column
              show-overflow-tooltip
              label="商品编码"
              align="center"
              prop="goodsNo"
              width="180"
            >
            </el-table-column>
            <el-table-column
              label="商品名称"
              align="center"
              prop="goodsName"
              width="120"
            />
            <el-table-column
              label="商品类别"
              align="center"
              prop="categoryName"
            />
            <el-table-column
              label="商品条码"
              align="center"
              prop="barcode"
              width="120"
            />
            <el-table-column label="规格" align="center" prop="goodsSpec" />
            <el-table-column label="单位" align="center" prop="unitName" />
            <el-table-column label="仓库" align="center" prop="storeName" />
            <el-table-column label="数量" align="center" prop="unitFirstQty" />
            <el-table-column
              label="成本"
              align="center"
              prop="unitFirstPrice"
            />
            <el-table-column
              label="期初总金额"
              align="center"
              prop="billMoney"
            />
          </el-table>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :isSelectAll="!isAlreadyInitial"
            :idsCom="ids"
            :totalCom="total"
            @selectAllCom="selectAll"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import bottomPage from '@/views/components/bottomPage' //底部分页
import { allStoreList } from '@/api/system/store' //仓库
import { listCategory } from '@/api/goods/category' //商品类别
import {
  goodsStockListAPI,
  goodsStockDeleteAPI,
  initialDataGoodsStoreBook,
  isAlreadyInitialDataBookDetail
} from '@/api/system/initialData/goodsStock' //期初建账
import operatingButton from '@/views/components/operatingButton' //操作按钮
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
export default {
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    operatingButton,
    SelectRemote
  },
  data () {
    return {
      storeData: [], //仓库数据
      categoryList: [], //商品类别数据
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'goodsNos', name: '商品编号' },
        { parameter: 'goodsNames', name: '商品名称' },
        { parameter: 'barcodes', name: '商品条码' }
      ],
      loadingTable: false, //表格的加载
      isAlreadyInitial: false, //是否期初建账
      multiple: true, // 非单个禁用
      showHigh: false, //是否显示高级搜索
      // 选中数组
      ids: [],
      // 总条数
      total: 0,
      //表格数据
      tableData: [],
      //查询
      queryParams: { pageNum: 1, pageSize: 15 },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  async created () {
    const res = await isAlreadyInitialDataBookDetail()
    this.isAlreadyInitial = res.isAlreadyInitial
    await this.getInitializeData()
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 140
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //删除按钮
    async handleDelete () {
      this.$confirm('是否删除所选数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await goodsStockDeleteAPI(this.ids)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          await this.getList()
        })
        .catch(() => {})
    },
    //初始化下拉框
    async getInitializeData () {
      //生产退料单
      await this.getList()
      //商品类别
      const res1 = await listCategory()
      this.categoryList = res1.rows
      //获取仓库详情
      const res2 = await allStoreList()
      this.storeData = res2.data
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await goodsStockListAPI(this.queryParams)
      this.tableData = rows
      this.tableData.forEach(item => {
        let unitFirstQty = Number(item.unitFirstQty) || 0
        let unitFirstPrice = Number(item.unitFirstPrice) || 0
        let billMoney = this.formatNumber(unitFirstQty * unitFirstPrice)
        this.$set(item, 'billMoney', billMoney)
      })
      this.total = total
      this.loadingTable = false
    },
    //期初建账
    async getOpeningAccount () {
      await initialDataGoodsStoreBook()
      this.isAlreadyInitial = true
      this.$message({
        message: '期初建账成功',
        type: 'success'
      })
    },
    //是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await goodsStockListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        this.tableData.forEach(item => {
          let unitFirstQty = Number(item.unitFirstQty) || 0
          let unitFirstPrice = Number(item.unitFirstPrice) || 0
          let billMoney = this.formatNumber(unitFirstQty * unitFirstPrice)
          this.$set(item, 'billMoney', billMoney)
        })
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //新增单据事件
    handleAdd () {
      this.$router.push({
        name: 'goodsStockDetail'
      })
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.firstBookId) //单据id
      this.multiple = !selection.length
    }
  }
}
</script>
<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  .goodsInfo {
    height: calc(100% - 94px);
    //单据表格
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
