var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            width: "600px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "date",
                  label: "操作",
                  width: "120px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-circle-plus operatePush",
                          class: { disabled: _vm.tableData.length >= 20 },
                          on: {
                            click: function ($event) {
                              return _vm.rowAvailable("push")
                            },
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-remove operateDel",
                          class: { disabled: _vm.tableData.length === 1 },
                          on: {
                            click: function ($event) {
                              return _vm.clearLastRowDataAge(scope.$index)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "nutrientName",
                  label: "营养物质",
                  width: "280px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { width: "120px" },
                          on: {
                            input: function ($event) {
                              scope.row.isChanged = true
                            },
                          },
                          model: {
                            value: scope.row.nutrientName,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "nutrientName", $$v)
                            },
                            expression: "scope.row.nutrientName",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "nutrientUnit", label: "单位", align: "center" },
                on: {
                  input: function ($event) {
                    _vm.scope.row.isChanged = true
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: { width: "40px" },
                          model: {
                            value: scope.row.nutrientUnit,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "nutrientUnit", $$v)
                            },
                            expression: "scope.row.nutrientUnit",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateGoodNutrient },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }